import React, { Fragment, useEffect, useState } from 'react'
import { ApiHook, Head, Grid, Footer, Link, Slider, Translate } from '@components'
import locale from '@data/locales/segment'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/Segment.module.styl'

export default ({ location: { pathname }, pageContext: _pageContext }) => {
  const { id, icon, meta_cover, ...segmentData } = ApiHook(_pageContext, `segments/${_pageContext.slug_pt}`)
  const query = useStaticQuery(graphql`
    query {
      allSegments {
        nodes {
          id
          icon
          name_pt
          name_en
          name_es
          slug_pt
          slug_en
          slug_es
        }
      }
    }
  `)
  const segments = ApiHook(query.allSegments.nodes, 'segments?max=9999', array => array.filter(s => s.id !== id))
  const constructions = segmentData.constructions?.slice(2)
  const highlights = segmentData.constructions?.slice(0, 2)
  const [highlight, setHighlight] = useState(0)
  const [lastHighlightSlide, setLastHighlightSlide] = useState(1)
  const [segmentSlide, setSegmentSlide] = useState(0)
  const [lastSegmentSlide, setLastSegmentSlide] = useState(1)
  useEffect(() => {
    const onWindowResize = () => {
      if (window.matchMedia('(min-width: 1880px)').matches) setLastHighlightSlide(2)
      else setLastHighlightSlide(1)
      if (window.matchMedia('(min-width: 1430px)').matches) setLastSegmentSlide(3)
      else if (window.matchMedia('(min-width: 1300px)').matches) setLastSegmentSlide(2)
      else if (window.matchMedia('(min-width: 1170px)').matches) setLastSegmentSlide(3)
      else if (window.matchMedia('(min-width: 700px)').matches) setLastSegmentSlide(2)
      else setLastSegmentSlide(1)
    }
    onWindowResize()
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return <>
    {Translate(segmentData, 'name', title => <Head title={`${title} - Rotesma`} path={pathname} image={meta_cover} />)}

    <header className={st.header}>
      <Grid className={st.grid}><div>
        <h1>{Translate(segmentData, 'name')}</h1>
        <div className={st.icon}><img src={icon} /></div>
      </div></Grid>
    </header>

    {highlights && <section className={st.highlights} id="segment-highlights">
      <div>
        <Grid className={st.grid}>
          <div className={st.controls}>
            <button className={cn('icon-arrow', highlight === 0 && st.disabled)} onClick={() => setHighlight(highlight - 1)}></button>
            <button className={cn('icon-arrow', highlight >= highlights.length - lastHighlightSlide && st.disabled)} onClick={() => setHighlight(highlight + 1)}></button>
          </div>
        </Grid>
      </div>
      <Grid className={st.grid}>
        <Slider slidesToScroll={1} currentSlide={highlight} goToSlide={setHighlight} lastSlide={highlights.length - lastHighlightSlide} className={st.slider} finite dots>
          {highlights.map(({ cover, ...construction }, key) => <div key={key} className={st.slide}>
            {Translate(construction, 'slug', slug => <Link to={slug} prefix="construction" className={st.thumbnail} style={{ backgroundImage: `url(${cover})` }}></Link>)}
            <h4>{Translate(construction, 'title')}</h4>
            {Translate(construction, 'subtitle', subtitle => <p dangerouslySetInnerHTML={{ __html: (subtitle || '').replace(/\n/g, '<br/>') }}></p>)}
            {Translate(construction, 'slug', slug => <Link to={slug} prefix="construction">{Translate(locale, 'view_construction')}<span className="icon-arrow"></span></Link>)}
          </div>)}
        </Slider>
      </Grid>
    </section>}

    {Boolean(constructions && constructions.length) && <section className={st.constructions} id="segment-constructions">
      <Grid>
        <ul>
          {constructions.map(({ cover, city, state, ...construction }, key) => <li key={key}>
            {Translate(construction, 'slug', slug => <Link to={slug} prefix="construction">
              <div className={st.thumbnail} style={{ backgroundImage: `url(${cover})` }}></div>
              <div>
                <div>
                  <h4>{Translate(construction, 'title')}</h4>
                  <p>{city} {state}</p>
                </div>
                <span className="icon-big-arrow"></span>
              </div>
            </Link>)}
          </li>)}
        </ul>
      </Grid>
    </section>}

    <section className={st.others} id="segment-others">
      <div><Grid>
        <h3>{Translate(locale, 'others')}</h3>
        <div className={st.controls}>
          <button className={cn('icon-arrow', segmentSlide === 0 && st.disabled)} onClick={() => setSegmentSlide(segmentSlide - 1)}></button>
          <button className={cn('icon-arrow', segmentSlide >= segments.length - lastSegmentSlide && st.disabled)} onClick={() => setSegmentSlide(segmentSlide + 1)}></button>
        </div>
      </Grid></div>
      <Grid>
        <Slider slidesToScroll={1} currentSlide={segmentSlide} goToSlide={setSegmentSlide} lastSlide={segments.length - lastSegmentSlide} className={st.slider} finite dots>
          {segments.map(({ icon, ...segment }, key) => <Fragment key={key}>{Translate(segment, 'slug', slug => <Link to={slug} prefix="segment" className={st.slide}>
            <h4>{Translate(segment, 'name')}</h4>
            <div className={st.icon}><img src={icon} /></div>
          </Link>)}</Fragment>)}
        </Slider>
      </Grid>
    </section>

    <Footer />
  </>
}