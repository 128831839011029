export default {
  title_pt: 'Rotesma - Segmentos',
  title_en: 'Rotesma - Segments',
  title_es: 'Rotesma - Áreas',
  view_construction_pt: 'Conheça essa obra',
  view_construction_en: 'Conheça essa obra',
  view_construction_es: 'Conozca esta obra',
  others_pt: 'Outros segmentos',
  others_en: 'Outros segmentos',
  others_es: 'Otras áreas'
}
