// extracted by mini-css-extract-plugin
export var constructions = "Segment-module--constructions--50d94";
export var controls = "Segment-module--controls--52739";
export var disabled = "Segment-module--disabled--3beaf";
export var grid = "Segment-module--grid--91f7e";
export var header = "Segment-module--header--bed03";
export var highlights = "Segment-module--highlights--da60e";
export var icon = "Segment-module--icon--ee98d";
export var others = "Segment-module--others--ff30a";
export var slide = "Segment-module--slide--f52c6";
export var slider = "Segment-module--slider--caf7e";
export var thumbnail = "Segment-module--thumbnail--9aad0";